<template>
  <div>
    <!-- Desktop -->
    <div class="d-none d-md-block">
      <v-app-bar elevation="0" class="px-16"
        style="border-top: 1px solid #d9d9d9; border-bottom: 1px solid #d9d9d9; z-index: 999;" fixed>
        <div style="width: 155px; height:64px">
          <v-img lazy-src="../../../../../public/img/wamasolplus-03.svg"
            src="../../../../../public/img/wamasolplus-03.png" style="width: 160px; height:60px"></v-img>
        </div>
        <v-spacer></v-spacer>
        <a href="#" class="pr-5">
          <p class="mb-0 menuSupLinkMine OpenSansRegular">Inicio
          </p>
        </a>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <p class="mb-0 menuSupLinkMine standarMine OpenSansRegular pr-5" v-bind="attrs" v-on="on">Quienes somos
              <v-icon>{{ icons.mdiChevronDown }}
              </v-icon>
            </p>
          </template>
          <v-list>
            <v-list-item>
              <a href="#conecta" class="">
                <p class="mb-0 menuSupLinkMine">La plataforma
                </p>
              </a>
            </v-list-item>
            <v-list-item>
              <a href="#orientada" class="">
                <p class="mb-0 menuSupLinkMine">Los afiliados
                </p>
              </a>
            </v-list-item>
            <v-list-item>
              <a href="#div" class="">
                <p class="mb-0 menuSupLinkMine">Marcas
                </p>
              </a>
            </v-list-item>
            <v-list-item>
              <a href="#equipo" class="">
                <p class="mb-0 menuSupLinkMine">Equipo de trabajo
                </p>
              </a>
            </v-list-item>
            <v-list-item>
              <a href="#historia" class="">
                <p class="mb-0 menuSupLinkMine">Nuestra historia
                </p>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
        <a href="#productos" class="pr-5">
          <p class="mb-0 menuSupLinkMine">Productos
          </p>
        </a>
        <a href="#socios" class="pr-5">
          <p class="mb-0 menuSupLinkMine">Socios
          </p>
        </a>
        <a href="#testimonios" class="pr-5">
          <p class="mb-0 menuSupLinkMine">Testimonios
          </p>
        </a>
        <v-divider vertical class="mx-4 py-6"></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="mb-0 d-flex justify-space-around align-center" v-bind="attrs" v-on="on">
              <v-img class="teamPhotoMine ml-2" lazy-src="../../../../../public/img/spain.png"
                src="../../../../../public/img/spain.png"
                style="background-position: inherit;max-width: 25px; max-height:15px">
              </v-img>
              <v-icon class="ml-2">{{ icons.mdiChevronDown }}</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item>
              <a href="#" class="d-flex justify-center align-center">
                <v-img class="teamPhotoMine" lazy-src="../../../../../public/img/UK.png"
                  src="../../../../../public/img/UK.png"
                  style="background-position: inherit;max-width: 25px; max-height:15px">
                </v-img>
                <p class="px-2 mb-0 menuSupLinkMine">English
                </p>
              </a>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
            <v-list-item>
              <div class="d-flex justify-space-between align-center">
                <a href="#" class="d-flex justify-center align-center mr-4">
                  <v-img class="teamPhotoMine" lazy-src="../../../../../public/img/spain.png"
                    src="../../../../../public/img/spain.png"
                    style="background-position: inherit;max-width: 25px; max-height:15px">
                  </v-img>
                  <p class="px-2 mb-0 menuSupLinkMine">Spanish
                  </p>
                </a>
                <v-icon color="success">{{ icons.mdiCheck }}</v-icon>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider vertical class="mx-4 py-6"></v-divider>
        <v-btn color="#f08319" class="OpenSansSemibold" style="box-shadow: none !important; color:white" rounded>Login
        </v-btn>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
  mdiMenu,
  mdiCheck,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiMenu,
        mdiCheck,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

.menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
