<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <MenuSuperior />
      <v-container grid-list-xs class="px-14">
        <v-row class="pt-10">
          <v-col cols="12">
            <SecOne />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 lightBackBlueMine mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <SecTwo />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <Secthree />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 lightBackBlueV2Mine mx-0" style="min-width:100%">
        <v-row class="">
          <v-col cols="12">
            <SecFour />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <SecFive />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <SecSix />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <SecSeven />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 pb-16 lightBackBlueV2Mine mx-0" style="min-width:100%">
        <v-row class="">
          <v-col cols="12">
            <SecEight />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 mx-0" style="min-width:100%">
        <v-row class="py-8">
          <v-col cols="12">
            <SecNine />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="px-15 mt-16 darkBlueMine mx-0" style="min-width:100%">
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-app-bar fixed color="#f4f5fa" elevation="1" class="pt-0">
        <div style="width: 105px; height:60px" class="pt-2">
          <v-img lazy-src="../../../../public/img/wamasolplus-03.svg" src="../../../../public/img/wamasolplus-03.svg">
          </v-img>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon class="mx-4" @click.stop="drawer = !drawer">
          <v-icon>{{ icons.mdiMenu }}</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-row class="pt-5">
          <v-col cols="12">
            <SecOne />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="lightBackBlueMine mx-0">
        <v-row class="py-5">
          <v-col cols="12">
            <SecTwo />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mx-0">
        <v-row class="py-8">
          <v-col cols="12">
            <Secthree />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="lightBackBlueV2Mine mx-0">
        <v-row class="">
          <v-col cols="12">
            <SecFour />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="mx-0">
        <v-row class="py-8">
          <v-col cols="12">
            <SecFive />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="mx-0">
        <v-row class="py-8">
          <v-col cols="12">
            <SecSix />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="mx-0">
        <v-row class="py-8">
          <v-col cols="12">
            <SecSeven />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="pb-16 lightBackBlueV2Mine mx-0">
        <v-row class="">
          <v-col cols="12">
            <SecEight />
          </v-col>
        </v-row>
      </v-container>
      <v-container id="historia" grid-list-xs class="mx-0">
        <v-row class="py-8">
          <v-col cols="12">
            <SecNine />
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs class="darkBlueMine mx-0">
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer v-model="drawer" fixed style="width: 100%;">
        <v-list dense class="py-0">
          <!-- Logo y close button -->
          <div class="mt-2 mb-6 mx-4 d-flex justify-space-between align-center">
            <div style="width: 110px; height:34px">
              <v-img lazy-src="../../../../public/img/wamasolplus-03.svg"
                src="../../../../public/img/wamasolplus-03.svg"></v-img>
            </div>
            <a @click="drawer = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </a>
          </div>
          <!-- Sesion -->
          <v-list-item link>
            <v-list-item-content>
              <div class="d-flex justify-space-between align-center">
                <p class="mb-0 menuSupLinkMine standarMine">
                  <v-icon medium class="mr-1">{{ icons.mdiAccountCircle }}</v-icon>
                  Inicia sesión
                </p>
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-4"></v-divider>

          <!-- idioma -->
          <v-list-item link @click.stop="idioma = !idioma">
            <v-list-item-content>
              <div class="d-flex justify-space-between align-center">
                <p class="mb-0 menuSupLinkMine standarMine">
                  Cambiar de idioma
                </p>
                <div class="d-flex justify-space-between align-center">
                  <v-img class="teamPhotoMine mr-2" lazy-src="../../../../public/img/spain.png"
                    src="../../../../public/img/spain.png"
                    style="background-position: inherit;max-width: 25px; max-height:15px">
                  </v-img>
                  <p class="mb-0 menuSupLinkMine standarMine">
                    Español
                  </p>
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <!-- Navigation Drawer idioma -->
          <v-navigation-drawer right v-model="idioma" fixed style="width: 100%;">
            <v-list dense class="py-0">
              <div class="ma-4 d-flex justify-space-between align-center">
                <a class="d-flex justify-space-between align-center" @click.stop="drawer = !drawer">
                  <v-icon class="mr-4">{{ icons.mdiChevronLeft }}</v-icon>
                  <p class="mb-0 menuSupLinkMine standarMine">
                    Elegir idioma
                  </p>
                </a>
                <a @click.stop="drawer = !drawer">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </a>
              </div>
              <v-divider></v-divider>
              <v-list-item link class="mt-4">
                <v-list-item-content>
                  <div class="d-flex justify-start align-center">
                    <v-img class="teamPhotoMine mr-2" lazy-src="../../../../public/img/spain.png"
                      src="../../../../public/img/spain.png"
                      style="background-position: inherit;max-width: 25px; max-height:15px">
                    </v-img>
                    <p class="mb-0 menuSupLinkMine standarMine">
                      Español
                    </p>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-content>
                  <div class="d-flex justify-start align-center">
                    <v-img class="teamPhotoMine mr-2" lazy-src="../../../../public/img/UK.png"
                      src="../../../../public/img/UK.png"
                      style="background-position: inherit;max-width: 25px; max-height:15px">
                    </v-img>
                    <p class="mb-0 menuSupLinkMine standarMine">
                      English
                    </p>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>

          <v-divider class="mx-4"></v-divider>

          <v-list-item link class="mt-4">
            <v-list-item-content>
              <a href="#" class="px-2">
                <p class="mb-0 menuSupLinkMine">Inicio
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <p class="mb-0 mx-6 menuSupLinkMine standarMine py-2">Quienes somos
          </p>

          <v-list-item link>
            <v-list-item-content>
              <a href="#conecta" class="px-6">
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>La plataforma
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#orientada" class="px-6">
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>Los afiliados
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#div" class="px-6">
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>Marcas
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#equipo" class="px-6">
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>Equipo de trabajo
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#historia" class="px-6">
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>Nuestra historia
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#productos" class="px-2">
                <p class="mb-0 menuSupLinkMine">Productos
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#socios" class="px-2">
                <p class="mb-0 menuSupLinkMine">Socios
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a href="#testimonios" class="px-2 mr-2">
                <p class="mb-0 menuSupLinkMine">Testimonios
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-navigation-drawer>

    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiMenu,
  mdiClose,
  mdiAccountCircle,
} from '@mdi/js'

import MenuSuperior from './utils/menuSuperior.vue'
import Secfooter from './utils/Secfooter.vue'
import SecOne from './utils/SecOne.vue'
import SecTwo from './utils/SecTwo.vue'
import Secthree from './utils/Secthree.vue'
import SecFour from './utils/SecFour.vue'
import SecNine from './utils/SecNine.vue'
import SecFive from './utils/SecFive.vue'
import SecSeven from './utils/SecSeven.vue'
import SecEight from './utils/SecEight.vue'
import SecSix from './utils/SecSix.vue'

export default {
  data() {
    return {
      drawer: false,
      idioma: false,
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiChevronRight,
        mdiChevronLeft,
        mdiMenu,
        mdiAccountCircle,
        mdiClose,
      },
    }
  },
  components: {
    MenuSuperior,
    SecOne,
    SecTwo,
    Secthree,
    SecFour,
    SecFive,
    SecSix,
    SecSeven,
    SecEight,
    SecNine,
    Secfooter,
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth !important;
}

.lightBackBlueMine {
  background-color: #0198c7;
}

.lightBackBlueV2Mine {
  background-color: #0199c797;
}

.darkBlueMine {
  background-color: #1a5666;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

a>.menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
