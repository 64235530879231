<template>
  <div id="orientada">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-16">
        <v-row>
          <v-col cols="12">
            <p class="OpenSansRegular mediumBlueMine punt52Mine" style="text-align: center;">Orientada a satisfacer las
              necesidades de operación turística de las compañía de la industria de viajes en todo el mundo</p>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="6" class="pr-10">
            <div class="d-flex justify-start align-end mb-5">
              <div style="width: 50px; height:50px">
                <v-img lazy-src="../../../../../public/img/Hotel3estrellas.png"
                  src="../../../../../public/img/Hotel3estrellas.png">
                </v-img>
              </div>
              <p class="mb-0 OpenSansLight mediumBlueMine punt32Mine capLetterMine">proveedores:</p>
            </div>
            <p class="standarMine OpenSansLight">Aerolíneas, cadenas hoteleras, rentadoras de autos, marinas, hostales,
              alquileres vacacionales, guías turísticos, que ofrecen sus productos en nuestra plataforma con el objetivo
              de multiplicar sus canales de venta.</p>
          </v-col>
          <v-col cols="6">
            <div class="d-flex justify-start align-end mb-5">
              <div style="width: 50px; height:50px">
                <v-img lazy-src="../../../../../public/img/empresa_viajes.png"
                  src="../../../../../public/img/empresa_viajes.png">
                </v-img>
              </div>
              <p class="mb-0 OpenSansLight mediumBlueMine punt32Mine capLetterMine">Agencias de Viajes y Turoperadores:
              </p>
            </div>
            <p class="standarMine OpenSansLight">Operadores turísticos que se registran en nuestra plataforma con el
              objetivo de ampliar la oferta que brindan a sus clientes a través de todos sus canales de venta: web,
              móvil, social, presencial, telefónica.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm class="">
        <v-row>
          <v-col cols="12">
            <p class="OpenSansSemibold mediumBlueMine punt52MineMov mb-0">Orientada a satisfacer las necesidades de operación
              turística de las compañía de la industria de viajes en todo el mundo</p>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12">
            <div style="width: 50px; height:50px">
              <v-img lazy-src="../../../../../public/img/Hotel3estrellas.png"
                src="../../../../../public/img/Hotel3estrellas.png">
              </v-img>
            </div>
            <p class="OpenSansSemibold mediumBlueMine punt32MineMov capLetterMine">proveedores:</p>
            <p class="standarMine OpenSansLight punt32MineMov">Aerolíneas, cadenas hoteleras, rentadoras de autos, marinas, hostales,
              alquileres vacacionales, guías turísticos, que ofrecen sus productos en nuestra plataforma con el objetivo
              de multiplicar sus canales de venta.</p>
          </v-col>
          <v-col cols="12">
            <div style="width: 50px; height:50px">
              <v-img lazy-src="../../../../../public/img/empresa_viajes.png"
                src="../../../../../public/img/empresa_viajes.png">
              </v-img>
            </div>
            <p class="OpenSansSemibold mediumBlueMine punt32MineMov capLetterMine">Agencias de Viajes y Turoperadores:
            </p>
            <p class="standarMine OpenSansLight punt32MineMov">Operadores turísticos que se registran en nuestra plataforma con el
              objetivo de ampliar la oferta que brindan a sus clientes a través de todos sus canales de venta: web,
              móvil, social, presencial, telefónica.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 22px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
