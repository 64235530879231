<template>
  <div id="econo">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="mt-15">
        <v-row>
          <v-col cols="12" md="7">
            <p class="mediumBlueMine OpenSansLight punt55Mine capLetterMine">Economiza tu tiempo y tus esfuerzos para
              gestionar
              destinos, viajes y servicios turísticos</p>
            <p class="standarMine OpenSansLight punt32Mine">Amplia tus posibilidades de negocios y proporciona múltiples
              experiencias a tus clientes, desde la
              comodidad de una plataforma única.</p>
            <v-btn class="OpenSansSemibold mt-10" tile color="#1A5666" style="box-shadow: none !important; color:white">
              Registrarse</v-btn>
          </v-col>
          <v-col cols="12" md="5">
            <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-02.png" width="auto" height="500px"
              src="../../../../../public/img/LandinPWamaRecursos-02.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm class="pt-10">
        <v-row>
          <v-col cols="12" md="7">
            <p class="mediumBlueMine OpenSansLight punt55MineMov capLetterMine">Economiza tu tiempo y tus esfuerzos para
              gestionar
              destinos, viajes y servicios turísticos</p>
            <p class="standarMine OpenSansLight punt32MineMov">Amplia tus posibilidades de negocios y proporciona múltiples
              experiencias a tus clientes, desde la
              comodidad de una plataforma única.</p>
            <v-btn class="OpenSansSemibold mt-10" tile color="#1A5666" style="box-shadow: none !important; color:white">
              Registrarse</v-btn>
          </v-col>
          <v-col cols="12" md="5">
            <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-02.png" width="auto" height="400px"
              src="../../../../../public/img/LandinPWamaRecursos-02.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
