<template>
  <div id="div">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-16">
        <v-row>
          <v-col cols="12">
            <p class="mb-10 OpenSansSemibold mediumBlueMine punt61Mine" style="text-align: center;">Nuestras divisiones
            </p>
          </v-col>
        </v-row>
        <v-row class="px-16">
          <v-col cols="4">
            <template>
              <v-card class="mx-auto" max-width="344" elevation="6" tile height="430"
                style="background-color: #1a566653;">
                <div style="width:100%; height:auto; background-color: white;" class="pa-6">
                  <v-img lazy-src="../../../../../public/img/wamasolplus-03.png"
                    src="../../../../../public/img/wamasolplus-03.png" style="width: 240px; height:90px"></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  <b>División de ventas mayoristas</b> de Wamasol S.A., basada en una plataforma de viajes B2B, ofrece a
                  las compañias registradas la posibilidad de expandir sus negocios:
                  <ul class="pt-1">
                    <li>Multiplicando los canales de venta de los proveedores, y</li>
                    <li>Ampliando la cartera de productos de las Agencias de viajes y Turoperadores afiliados.</li>
                  </ul>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
          <v-col cols="4">
            <template>
              <v-card class="mx-auto" max-width="344" elevation="6" tile height="430"
                style="background-color: #f0831953;">
                <div style="width:100%; height:auto; background-color: white;" class="pa-6">
                  <v-img lazy-src="../../../../../public/img/wamasoltours-03.png"
                    src="../../../../../public/img/wamasoltours-03.png" style="width: 240px; height:90px"></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  <b>Agencia de viajes</b> especializada en turismo multidestino. Conecta a clientes de Europa, Estados
                  Unidos y Canadá con la región del Caribe y Centro América, privilegiando en su oferta turística los
                  destinos de Cuba y Guatemala.
                </v-card-text>
              </v-card>
            </template>
          </v-col>
          <v-col cols="4">
            <template>
              <v-card class="mx-auto" max-width="344" elevation="6" tile height="430"
                style="background-color: #0198c753;">
                <div style="width:100%; height:auto; background-color: white;" class="pa-6">
                  <v-img lazy-src="../../../../../public/img/wamasoltech-03.png"
                    src="../../../../../public/img/wamasoltech-03.png" style="width: 240px; height:90px"></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  Es la <b>División de tecnologías</b> de Wamasol S.A. encargada del desarrollo de aplicaciones web y
                  móviles para comercio electrónico, tanto propias como a pedido para terceros, dirigidas principalmente
                  a
                  la industria turística y tiendas en línea.
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-xm>
        <v-row>
          <v-col cols="12">
            <p class="OpenSansSemibold mediumBlueMine punt61MineMov" style="text-align: center;">Nuestras divisiones
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-slide-group v-model="model" show-arrows>
        <v-slide-item>
          <v-card class="mr-4" height="auto" width="250" elevation="6" tile style="background-color: #1a566653;">
            <div style="width:250px; height:100px; background-color: white;" class="pa-3">
              <v-img lazy-src="../../../../../public/img/wamasolplus-03.png"
                src="../../../../../public/img/wamasolplus-03.png"></v-img>
            </div>
            <v-card-text class="pa-4">
              <p class="standarMine OpenSansLight">
                <b class="standarMine OpenSansLight">División de ventas mayoristas</b> de Wamasol S.A., basada en una
                plataforma de viajes B2B, ofrece a
                las compañias registradas la posibilidad de expandir sus negocios:
              </p>
              <ul class="pt-1">
                <li class="standarMine OpenSansLight">Multiplicando los canales de venta de los proveedores, y</li>
                <li class="standarMine OpenSansLight">Ampliando la cartera de productos de las Agencias de viajes y
                  Turoperadores afiliados.</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item>
          <v-card class="mr-4" height="auto" width="250" elevation="6" tile style="background-color: #f0831953;">
            <div style="width:250px; height:100px; background-color: white;" class="pa-3">
              <v-img lazy-src="../../../../../public/img/wamasoltours-03.png"
                src="../../../../../public/img/wamasoltours-03.png"></v-img>
            </div>
            <v-card-text class="pa-4">
              <p class="mb-0 standarMine OpenSansLight">
                <b class="standarMine">Agencia de viajes</b> especializada en turismo multidestino. Conecta a clientes
                de Europa, Estados
                Unidos y Canadá con la región del Caribe y Centro América, privilegiando en su oferta turística los
                destinos de Cuba y Guatemala.
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item>
          <v-card class="mr-4" height="auto" width="250" elevation="6" tile style="background-color: #0198c753;">
            <div style="width:250px; height:100px; background-color: white;" class="pa-3">
              <v-img lazy-src="../../../../../public/img/wamasoltech-03.png"
                src="../../../../../public/img/wamasoltech-03.png"></v-img>
            </div>
            <v-card-text class="pa-4 standarMine OpenSansLight">
              <p class="standarMine OpenSansLight">
                Es la <b class="standarMine OpenSansLight">División de tecnologías</b> de Wamasol S.A. encargada del
                desarrollo de aplicaciones web y
                móviles para comercio electrónico, tanto propias como a pedido para terceros, dirigidas principalmente
                a
                la industria turística y tiendas en línea.</p>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  data: () => ({
    model: null,
  }),
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
