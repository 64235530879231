<template>
  <div>
    <v-container grid-list-sm class="px-5 mt-16" id="nuestros">
      <v-row>
        <v-col cols="12" md="5">
          <v-img class="ma-2" lazy-src="../../../../../public/img/wamasol plus BLANCO-01.png"
            src="../../../../../public/img/wamasol plus BLANCO-01.png" style="width: 160px; height:145px">
          </v-img>
          <p class="whiteMine pr-15 punt14Mine OpenSansLight">
            Wamasol Plus es la división de Wamasol S.A., empresa constituida en Guatemala, C.A.; encargada de operar una
            plataforma B2B propia, especializada en paquetes turísticos multidestinos para el Caribe y Centroamérica.
          </p>
        </v-col>
        <v-col cols="12" md="2" class="mt-5">
          <p class="whiteMine punt32Mine OpenSansRegular">Servicios</p>
          <ul>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Vuelos</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Hoteles</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Traslados</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Renta de autos</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Excursiones</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Cruceros</p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">Buceo y Pesca</p>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="2" class="mt-5">
          <p class="whiteMine punt32Mine OpenSansRegular">Sobre nosotros</p>
          <ul class="whiteMine">
            <li>
              <a href="#conecta" class="menuSupLinkMine whiteMine punt14Mine OpenSansLight">
                La plataforma
              </a>
            </li>
            <li>
              <a href="#orientada" class="menuSupLinkMine whiteMine punt14Mine OpenSansLight">
                Los afiliados
              </a>
            </li>
            <li>
              <a href="#div" class="menuSupLinkMine whiteMine punt14Mine OpenSansLight">
                Marcas
              </a>
            </li>
            <li>
              <a href="#equipo" class="menuSupLinkMine whiteMine punt14Mine OpenSansLight">
                Equipo de trabajo
              </a>
            </li>
            <li>
              <a href="#historia" class="menuSupLinkMine whiteMine punt14Mine OpenSansLight">
                Nuestra historia
              </a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="3" class="mt-5">
          <p class="whiteMine punt32Mine OpenSansRegular">Contacto</p>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-center">
              <v-icon color="white" small>{{ icons.mdiPhoneInTalkOutline }}</v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">+502 4066 6270</p>
            </div>
          </div>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-center">
              <v-icon color="white" small>{{ icons.mdiEmailOutline }}</v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">wamasol.com</p>
            </div>
          </div>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="white" small>{{ icons.mdiMapMarkerOutline }}</v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">6A Ave. 6-91 Oficina 01, 4to. Nivel, Zona 9,
                Ciudad de Guatemala, Guatemala C.A., 01009</p>
            </div>
          </div>
          <v-divider class="mt-16" style="border-color:white"></v-divider>
          <div class="d-flex justify-end align-center">
            <a href="https://wa.me/message/PNXWZIPS5NHXM1" class="socialMine">
              <v-icon color="white">{{ icons.mdiWhatsapp }}</v-icon>
            </a>
            <a href="https://facebook.com/WamasolTours" class="socialMine">
              <v-icon color="white">{{ icons.mdiFacebook }}</v-icon>
            </a>
            <a href="https://instagram.com/wamasoltours" class="socialMine">
              <v-icon color="white">{{ icons.mdiInstagram }}</v-icon>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
  mdiPhoneInTalkOutline,
  mdiEmailOutline,
  mdiMapMarkerOutline,
  mdiWhatsapp,
  mdiFacebook,
  mdiInstagram,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiPhoneInTalkOutline,
        mdiEmailOutline,
        mdiMapMarkerOutline,
        mdiWhatsapp,
        mdiFacebook,
        mdiInstagram,
      },
    }
  },
  methods: {

  },
  data: () => ({
    model: null,
  }),
}
</script>

<style scoped>
html {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt61Mine {
  font-size: 40px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.punt14Mine {
  font-size: 14px;
}

.punt12Mine {
  font-size: 12px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}

.socialMine {
  /* border: 1px solid white; */
  border-radius: 500px;
  margin: 5px;
  margin-top: 8px;
  padding: 3px;
}

.menuSupLinkMine {
  text-decoration: none;
}
</style>
