<template>
  <div id="conecta">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-16">
        <v-row>
          <v-col cols="7">
            <p class="mb-0 whiteMine OpenSansRegular punt54Mine">Conecta historia, cultura, gente...</p>
            <p class="whiteMine OpenSansLight punt32Mine"><span class="OpenSansSemibold punt35Mine">Wamasol Plus es una
                plataforma de viajes business to business</span> que promueve
              paquetes de turismo multidestino en el Caribe y Centroamérica. Entre sus principales destinos están:
              Guatemala, corazón del Mundo Maya y Cuba con sus increíbles playas en el Caribe. Conectamos historia,
              tradiciones, naturaleza, cultura, patrimonio y, sobre todo, personas.</p>
          </v-col>
          <v-col cols="5">
            <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-01.png" width="auto" height="300px"
              src="../../../../../public/img/LandinPWamaRecursos-01.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm class="">
        <v-row>
          <v-col cols="12">
            <p class="whiteMine OpenSansRegular punt54MineMov">Conecta historia, cultura, gente...</p>
            <p class="whiteMine OpenSansLight punt32MineMov"><span class="OpenSansSemibold punt35Mine">Wamasol Plus es una
                plataforma de viajes business to business</span> que promueve
              paquetes de turismo multidestino en el Caribe y Centroamérica. Entre sus principales destinos están:
              Guatemala, corazón del Mundo Maya y Cuba con sus increíbles playas en el Caribe. Conectamos historia,
              tradiciones, naturaleza, cultura, patrimonio y, sobre todo, personas.</p>
          </v-col>
          <v-col cols="12">
            <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-01.png" width="auto" height="300px"
              src="../../../../../public/img/LandinPWamaRecursos-01.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt54Mine {
  font-size: 38px;
}

.punt54MineMov {
  font-size: 28px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
