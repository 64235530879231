<template>
  <div id="productos">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-10">
        <v-row class="pt-8">
          <v-col cols="5">
            <div style="width:100%; height:auto">
              <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-03.png"
                src="../../../../../public/img/LandinPWamaRecursos-03.png" style="max-width: 450px; heigth:auto"></v-img>
            </div>
          </v-col>
          <v-col cols="7">
            <p class="capLetterMine OpenSansSemibold punt52Mine mediumBlueMine" style="text-align:center">Nuestros
              productos</p>
            <div class="d-flex justify-center align-center mt-10">
              <div class="d-block mx-2">
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-07.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-07.svg"></v-img>
                  </div>
                  Vuelos
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-08.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-08.svg"></v-img>
                  </div>
                  Renta de autos
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-09.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-09.svg"></v-img>
                  </div>
                  Excursiones
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-11.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-11.svg"></v-img>
                  </div>
                  Yates & Marina
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-06.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-06.svg"></v-img>
                  </div>
                  Buceo y pesca
                </v-btn>
              </div>
              <div class="d-block mx-2">
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-10.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-10.svg"></v-img>
                  </div>
                  Hoteles
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-14.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-14.svg"></v-img>
                  </div>
                  Traslados
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-13.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-13.svg"></v-img>
                  </div>
                  Paquetes Turísticos
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-15.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-15.svg"></v-img>
                  </div>
                  Cruceros
                </v-btn>
                <v-btn color="white" width="100%" x-large style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine" tile>
                  <div style="width: 50px; height:50px">
                    <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-12.svg"
                      src="../../../../../public/img/LandinPWamaRecursos-12.svg"></v-img>
                  </div>
                  Turismo de salud
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container>
        <v-row class="">
          <v-col cols="12" md="5">
            <div style="width:100%; height:auto">
              <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-03.png"
                src="../../../../../public/img/LandinPWamaRecursos-03.png"></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <p class="capLetterMine OpenSansSemibold punt52MineMov mediumBlueMine mb-0 pb-5" style="text-align:center">
              Nuestros productos</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-07.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-07.svg"></v-img>
              </div>
              Vuelos
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-10.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-10.svg"></v-img>
              </div>
              Hoteles
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-08.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-08.svg"></v-img>
              </div>
              Renta de autos
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-14.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-14.svg"></v-img>
              </div>
              Traslados
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-09.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-09.svg"></v-img>
              </div>
              Excursiones
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-13.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-13.svg"></v-img>
              </div>
              Paquetes
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-11.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-11.svg"></v-img>
              </div>
              Yates & Marina
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-15.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-15.svg"></v-img>
              </div>
              Cruceros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-06.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-06.svg"></v-img>
              </div>
              Buceo y pesca
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn tile width="100%" x-large color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start">
              <div style="width: 30px; height:30px">
                <v-img lazy-src="../../../../../public/img/LandinPWamaRecursos-12.svg"
                  src="../../../../../public/img/LandinPWamaRecursos-12.svg"></v-img>
              </div>
              Tur Salud
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;

}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 12px;
}

.punt26Mine {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
