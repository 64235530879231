<template>
  <div id="socios">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-16">
        <v-row>
          <v-col cols="12">
            <p class="mb-0 OpenSansSemibold mediumBlueMine punt61Mine" style="text-align: center;">Nuestros Socios</p>
          </v-col>
        </v-row>
        <v-row class="px-16 mb-10">
          <v-col cols="12">
            <p class="standarMine OpenSansLight" style="text-align: center;">La plataforma está avalada por prestigiosas
              marcas de la industria
              turística en la región, que garantizan la fiabilidad de los servicios y la satisfacción del cliente final.
            </p>
          </v-col>
        </v-row>
        <v-row class="px-16">
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Iberia.svg" src="../../../../../public/img/Iberia.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-start" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/onlinetour.png" src="../../../../../public/img/onlinetour.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/iberojet.png" src="../../../../../public/img/iberojet.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Melia.svg" src="../../../../../public/img/Melia.svg">
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 2do row -->
        <v-row class="px-16">
          <v-col cols="4" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/melia_otro.svg" src="../../../../../public/img/melia_otro.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="4" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/paradisus.svg" src="../../../../../public/img/paradisus.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="4" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Sol_by_Melia.svg"
                src="../../../../../public/img/Sol_by_Melia.svg">
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 3er row -->
        <v-row class="px-16">
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/World2Fly.png" src="../../../../../public/img/World2Fly.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/GAVIOTA-TUR.png"
                src="../../../../../public/img/GAVIOTA-TUR.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/CUBATUR.png" src="../../../../../public/img/CUBATUR.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="3" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/viajes_cubanacan.png"
                src="../../../../../public/img/viajes_cubanacan.png">
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 4to row -->
        <!-- <v-row class="px-16">
          <v-col cols="4" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/onlinetour.png" src="../../../../../public/img/onlinetour.png">
              </v-img>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm class="">
        <v-row>
          <v-col cols="12">
            <p class="mb-0 OpenSansSemibold mediumBlueMine punt61MineMov">Nuestros Socios</p>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="12">
            <p class="standarMine OpenSansLight">La plataforma está avalada por prestigiosas
              marcas de la industria
              turística en la región, que garantizan la fiabilidad de los servicios y la satisfacción del cliente final.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Iberia.svg" src="../../../../../public/img/Iberia.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/iberojet.png" src="../../../../../public/img/iberojet.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Melia.svg" src="../../../../../public/img/Melia.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/melia_otro.svg" src="../../../../../public/img/melia_otro.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/paradisus.svg" src="../../../../../public/img/paradisus.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:100px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/Sol_by_Melia.svg"
                src="../../../../../public/img/Sol_by_Melia.svg">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/World2Fly.png" src="../../../../../public/img/World2Fly.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/GAVIOTA-TUR.png"
                src="../../../../../public/img/GAVIOTA-TUR.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/CUBATUR.png" src="../../../../../public/img/CUBATUR.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 150px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/viajes_cubanacan.png"
                src="../../../../../public/img/viajes_cubanacan.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="6" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/onlinetour.png" src="../../../../../public/img/onlinetour.png">
              </v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
