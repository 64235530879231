<template>
  <div id="historia">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container grid-list-sm class="px-16">
        <v-row>
          <v-col cols="12">
            <p class="mb-8 OpenSansSemibold mediumBlueMine punt61Mine" style="text-align: center;">Nuestra historia
            </p>
          </v-col>
        </v-row>
        <v-row class="px-0" id="background">
          <v-col cols="4" class="px-15">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/user.png" src="../../../../../public/img/user.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="8" class="px-15">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">2018
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Nacimiento de Wamasol Tours como proyecto.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Firma de primeros acuerdos de operacion turística
                    con OnlineTours en España, y en Cuba con los respectivos nacionales: IzlaAzul y Cubatur.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Inicio de operaciones turísticas con clientes
                    provenientes de España, Canadá y Puerto Rico.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="4" class="px-15">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/pyramid.png" src="../../../../../public/img/pyramid.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="8" class="px-15">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">2020
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Constitución de la empresa Wamasol Tours SA en
                    Guatemala.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Creación de Wamasol Tech, división de tecnología
                    de
                    la compañía para el desarrollo de aplicaciones web y móviles especializadas en turismo y comercio
                    electrónico.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="4" class="px-15">
            <v-img lazy-src="../../../../../public/img/invEdgar.jpeg" src="../../../../../public/img/invEdgar.jpeg"
              style="width: 250px; height:250px; border-radius: 500px;">
            </v-img>
          </v-col>
          <v-col cols="8" class="px-15">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">2021
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Inicio de la externacionalización de los
                    proyectos
                    de Wamasol Tech, firma del primer contrato de desarrollo para Two Way Travel en USA.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="4" class="px-15">
            <v-img id="planeCenter" lazy-src="../../../../../public/img/2022.png"
              src="../../../../../public/img/2022.png"
              style="max-width: 250px; width:250px; height:250px; border-radius: 1000px;">
            </v-img>
          </v-col>
          <v-col cols="8" class="px-15">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">2022
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine"> Participación en la feria internacional de
                    turismo de La Habana FITCUBA 2022. Ampliación de los contratos de Wamasol Tours con operadores
                    turísticos en Cuba: Gaviota Tours, Viajes Cubanacán, Amistur y SMC.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine"> Wamasol Tech amplia su cartera de clientes con
                    la firma de nuevos contratos de desarrollo con PROTURS (México), Viajes Kronos (España) y TravelZun
                    (USA).
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Creación de Wamasol Plus, plataforma de viajes
                    B2B para la venta de paquetes turísticos multidestinos, especializada en el mundo Maya y Cuba.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">Inicio de las negociaciones con operadores
                    turísticos en Guatemala, Cuba y España para la integración de productos en Wamasol Plus: TAG,
                    INGUAT, MINTUR, IACC, IBERIA.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm>
        <v-row>
          <v-col cols="12">
            <p class="mb-8 OpenSansSemibold mediumBlueMine punt61MineMov" style="text-align: center;">Nuestra historia
            </p>
          </v-col>
        </v-row>
        <v-row class="px-0" id="background">
          <v-col cols="12" align="center">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/user.png" src="../../../../../public/img/user.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">2018
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Nacimiento de Wamasol Tours como proyecto.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Firma de primeros acuerdos de operacion turística
                    con OnlineTours en España, y en Cuba con los respectivos nacionales: IzlaAzul y Cubatur.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Inicio de operaciones turísticas con clientes
                    provenientes de España, Canadá y Puerto Rico.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="12" align="center">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/pyramid.png" src="../../../../../public/img/pyramid.png">
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">2020
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Constitución de la empresa Wamasol Tours SA en
                    Guatemala.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Creación de Wamasol Tech, división de tecnología
                    de
                    la compañía para el desarrollo de aplicaciones web y móviles especializadas en turismo y comercio
                    electrónico.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="12" align="center">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/invEdgar.jpeg" src="../../../../../public/img/invEdgar.jpeg"
                style="border-radius: 500px;">
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">2021
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Inicio de la externacionalización de los
                    proyectos
                    de Wamasol Tech, firma del primer contrato de desarrollo para Two Way Travel en USA.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col cols="12" align="center">
            <div class="" style="width: 250px; height:250px;  z-index: 11;">
              <v-img lazy-src="../../../../../public/img/2022.png" src="../../../../../public/img/2022.png"
                style="border-radius: 500px;">
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">2022
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine"> Participación en la feria internacional de
                    turismo de La Habana FITCUBA 2022. Ampliación de los contratos de Wamasol Tours con operadores
                    turísticos en Cuba: Gaviota Tours, Viajes Cubanacán, Amistur y SMC.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine"> Wamasol Tech amplia su cartera de clientes con
                    la firma de nuevos contratos de desarrollo con PROTURS (México), Viajes Kronos (España) y TravelZun
                    (USA).
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Creación de Wamasol Plus, plataforma de viajes
                    B2B para la venta de paquetes turísticos multidestinos, especializada en el mundo Maya y Cuba.
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">Inicio de las negociaciones con operadores
                    turísticos en Guatemala, Cuba y España para la integración de productos en Wamasol Plus: TAG,
                    INGUAT, MINTUR, IACC, IBERIA.
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
} from '@mdi/js'

export default {
  setup() {
    return {

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {

  },
  data: () => ({
    model: null,
  }),
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../../../public/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../../../public/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../../../public/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1A5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.punt14Mine {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}
</style>
